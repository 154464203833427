import React from "react";

import PageContainer from "../../src/components/container/Container";

export default function Privacy_policy() {
  return (
    <PageContainer title="Privacy policy" description="">
      <section className="section">
        <div className="container">
          <div className="col-lg-12">
            <div className="title-heading justify-text">
              <div className="h3 fw-bold text-decoration-underline mb-2">
                Privacy Policy
              </div>
              <span className="fw-bold text-normal">
                {""}
                Last updated: January 19, 2023
              </span>
              <p className="indent text-muted h6 fw-normal mb-0">
                This Privacy Policy describes Our policies and procedures on the
                collection, use and disclosure of Your information when You use
                the Service and tells You about Your privacy rights and how the
                law protects You. We use Your Personal data to provide and
                improve the Service. By using the Service, You agree to the
                collection and use of information in accordance with this
                Privacy Policy. This Privacy Policy has been created with the
                help of the Free Privacy Policy Generator.
              </p>
              <div className="h3 fw-bold text-decoration-underline mb-0">
                {"Interpretation and Definitions"}
              </div>
              <div className="h4 fw-bold mb-0">Interpretation</div>
              <span className="fw-bold text-normal">
                <p className="indent text-muted h6 fw-normal">
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions. The following
                  definitions shall have the same meaning regardless of whether
                  they appear in singular or in plural.
                </p>
              </span>
              <div className="h4 fw-bold mb-0">Defination</div>
              <div className="indent h7 text-muted mb-0">
                For the purposes of this Privacy Policy:
              </div>
              <p className="indent text-muted h6 fw-normal">
                <ul>
                  <li>
                    Account means a unique account created for You to access our
                    Service or parts of our Service.
                  </li>
                  <li>
                    Company (referred to as either “the Company”, “We”, “Us” or
                    “Our” in this Agreement) refers to CodeElan Technologies
                    Pvt. Ltd. , E402, S3 Lifestyle Internal Rd, Apartments,
                    Pimple Saudagar, Pune, Maharashtra 411027.
                  </li>
                  <li>
                    Cookies are small files that are placed on Your computer,
                    mobile device or any other device by a website, containing
                    the details of Your browsing history on that website among
                    its many uses.
                  </li>
                  <li>Country refers to: Maharashtra, India</li>
                  <li>
                    Device means any device that can access the Service such as
                    a computer, a cellphone or a digital tablet.
                  </li>
                  <li>
                    Personal Data is any information that relates to an
                    identified or identifiable individual.
                  </li>
                  <li>Service refers to the Website.</li>
                  <li>
                    Service Provider means any natural or legal person who
                    processes the data on behalf of the Company. It refers to
                    third-party companies or individuals employed by the Company
                    to facilitate the Service, to provide the Service on behalf
                    of the Company, to perform services related to the Service
                    or to assist the Company in analyzing how the Service is
                    used.
                  </li>
                  <li>
                    Usage Datarefers to data collected automatically, either
                    generated by the use of the Service or from the Service
                    infrastructure itself (for example, the duration of a page
                    visit).
                  </li>
                  <li>
                    Websiterefers to CodeElan Technologies, accessible from
                    https://codeelan.com/
                  </li>
                  <li>
                    You means the individual accessing or using the Service, or
                    the company, or other legal entity on behalf of which such
                    individual is accessing or using the Service, as applicable.
                  </li>
                </ul>
              </p>
              <div className="h3 fw-bold text-decoration-underline mb-0">
                {"Collecting and Using Your Personal Data"}
              </div>
              <div className="h4 fw-bold">Types of Data Collected</div>
              <span className="h5 fw-semibold text-normal">Personal Data</span>
              <span className="fw-bold text-normal">
                <p className="indent text-muted h6 fw-normal">
                  While using Our Service, We may ask You to provide Us with
                  certain personally identifiable information that can be used
                  to contact or identify You. Personally identifiable
                  information may include, but is not limited to:
                  <ul>
                    <li>Email address</li>
                    <li>First name and last name</li>
                    <li> Phone number</li>
                    <li>Usage Data</li>
                  </ul>
                </p>
              </span>
              <span className="h5 fw-semibold text-normal">Usage Data</span>
              <span className="fw-bold text-normal">
                <p className="indent text-muted h6 fw-normal">
                  Usage Data is collected automatically when using the Service.
                  Usage Data may include information such as Your Device’s
                  Internet Protocol address (e.g. IP address), browser type,
                  browser version, the pages of our Service that You visit, the
                  time and date of Your visit, the time spent on those pages,
                  unique device identifiers and other diagnostic data. When You
                  access the Service by or through a mobile device, We may
                  collect certain information automatically, including, but not
                  limited to, the type of mobile device You use, Your mobile
                  device unique ID, the IP address of Your mobile device, Your
                  mobile operating system, the type of mobile Internet browser
                  You use, unique device identifiers and other diagnostic data.
                  We may also collect information that Your browser sends
                  whenever You visit our Service or when You access the Service
                  by or through a mobile device.
                </p>
              </span>
              <span className="h5 fw-semibold text-normal">
                Tracking Technologies and Cookies
              </span>
              <span className="fw-bold text-normal">
                <p className="text-muted h6 fw-normal">
                  We use Cookies and similar tracking technologies to track the
                  activity on Our Service and store certain information.
                  Tracking technologies used are beacons, tags, and scripts to
                  collect and track information and to improve and analyze Our
                  Service. The technologies We use may include:
                  <ul>
                    <li>
                      <div className="fw-bold text-dark">
                        Cookies or Browser Cookies :
                        <div className=" fw-normal text-muted">
                          A cookie is a small file placed on Your Device. You
                          can instruct Your browser to refuse all Cookies or to
                          indicate when a Cookie is being sent. However, if You
                          do not accept Cookies, You may not be able to use some
                          parts of our Service. Unless you have adjusted Your
                          browser setting so that it will refuse Cookies, our
                          Service may use Cookies.
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="fw-bold text-dark">
                        Web Beacons :
                        <div className=" fw-normal text-muted">
                          Certain sections of our Service and our emails may
                          contain small electronic files known as web beacons
                          (also referred to as clear gifs, pixel tags, and
                          single-pixel gifs) that permit the Company, for
                          example, to count users who have visited those pages
                          or opened an email and for other related website
                          statistics (for example, recording the popularity of a
                          certain section and verifying system and server
                          integrity).
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="indent fw-normal text-dark ">
                    We use both Session and Persistent Cookies for the purposes
                    set out below:
                  </div>
                  <ul>
                    <li className="indent fw-bold text-dark ">
                      Necessary / Essential Cookies
                      <div className=" fw-normal text-muted">
                        Type: Session Cookies
                      </div>
                      <div className="fw-normal text-muted">
                        Administered by: Us
                      </div>
                      <div className="fw-normal text-muted">
                        Purpose: These Cookies are essential to provide You with
                        services available through the Website and to enable You
                        to use some of its features. They help to authenticate
                        users and prevent fraudulent use of user accounts.
                        Without these Cookies, the services that You have asked
                        for cannot be provided, and We only use these Cookies to
                        provide You with those services.
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li className="indent fw-bold text-dark ">
                      Cookies Policy / Notice Acceptance Cookies
                      <div className=" fw-normal text-muted">
                        Type: Persistent Cookies
                      </div>
                      <div className="fw-normal text-muted">
                        Administered by: Us
                      </div>
                      <div className="fw-normal text-muted">
                        Purpose: These Cookies identify if users have accepted
                        the use of cookies on the Website.
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li className="indent fw-bold text-dark ">
                      Functionality Cookies
                      <div className=" fw-normal text-muted">
                        Type: Persistent Cookies
                      </div>
                      <div className="fw-normal text-muted">
                        Administered by: Us
                      </div>
                      <div className="fw-normal text-muted">
                        Purpose: These Cookies allow us to remember choices You
                        make when You use the Website, such as remembering your
                        login details or language preference. The purpose of
                        these Cookies is to provide You with a more personal
                        experience and to avoid You having to re-enter your
                        preferences every time You use the Website.
                      </div>
                    </li>
                  </ul>
                  <div className="indent fw-normal text-muted ">
                    For more information about the cookies we use and your
                    choices regarding cookies, please visit our Cookies Policy
                    or the Cookies section of our Privacy Policy.
                  </div>
                </p>
              </span>
              <div className="h4 fw-bold  ">Use of Your Personal Data</div>
              <span className="fw-bold text-normal">
                <p className="indent text-muted h6 fw-normal">
                  The Company may use Personal Data for the following purposes:
                  <ul>
                    <li>
                      To provide and maintain our Service, including to monitor
                      the usage of our Service.
                    </li>
                    <li>
                      To manage Your Account: to manage Your registration as a
                      user of the Service. The Personal Data You provide can
                      give You access to different functionalities of the
                      Service that are available to You as a registered user.
                    </li>
                    <li>
                      For the performance of a contract: the development,
                      compliance and undertaking of the purchase contract for
                      the products, items or services You have purchased or of
                      any other contract with Us through the Service.
                    </li>
                    <li>
                      To contact You: To contact You by email, telephone calls,
                      SMS, or other equivalent forms of electronic
                      communication, such as a mobile application’s push
                      notifications regarding updates or informative
                      communications related to the functionalities, products or
                      contracted services, including the security updates, when
                      necessary or reasonable for their implementation.
                    </li>
                    <li>
                      To provide You with news, special offers and general
                      information about other goods, services and events which
                      we offer that are similar to those that you have already
                      purchased or enquired about unless You have opted not to
                      receive such information.
                    </li>
                    <li>
                      To manage Your requests: To attend and manage Your
                      requests to Us.
                    </li>
                    <li>
                      For business transfers: We may use Your information to
                      evaluate or conduct a merger, divestiture, restructuring,
                      reorganization, dissolution, or other sale or transfer of
                      some or all of Our assets, whether as a going concern or
                      as part of bankruptcy, liquidation, or similar proceeding,
                      in which Personal Data held by Us about our Service users
                      is among the assets transferred.
                    </li>
                    <li>
                      For other purposes: We may use Your information for other
                      purposes, such as data analysis, identifying usage trends,
                      determining the effectiveness of our promotional campaigns
                      and to evaluate and improve our Service, products,
                      services, marketing and your experience.
                    </li>
                  </ul>
                  <span className="fw-normal text-dark">
                    We may share Your personal information in the following
                    situations:
                  </span>
                  <ul>
                    <li>
                      With Service Providers: We may share Your personal
                      information with Service Providers to monitor and analyze
                      the use of our Service, to contact You.
                    </li>
                    <li>
                      For business transfers: We may share or transfer Your
                      personal information in connection with, or during
                      negotiations of, any merger, sale of Company assets,
                      financing, or acquisition of all or a portion of Our
                      business to another company.
                    </li>
                    <li>
                      With Affiliates: We may share Your information with Our
                      affiliates, in which case we will require those affiliates
                      to honor this Privacy Policy. Affiliates include Our
                      parent company and any other subsidiaries, joint venture
                      partners or other companies that We control or that are
                      under common control with Us.
                    </li>
                    <li>
                      {" "}
                      With business partners: We may share Your information with
                      Our business partners to offer You certain products,
                      services or promotions.
                    </li>
                    <li>
                      With other users: when You share personal information or
                      otherwise interact in the public areas with other users,
                      such information may be viewed by all users and may be
                      publicly distributed outside.
                    </li>
                    <li>
                      With Your consent: We may disclose Your personal
                      information for any other purpose with Your consent.
                    </li>
                  </ul>
                </p>
              </span>
              <div className="h4 fw-bold">Retention of Your Personal Data </div>
              <p className="indent text-muted h6 fw-normal">
                The Company will retain Your Personal Data only for as long as
                is necessary for the purposes set out in this Privacy Policy. We
                will retain and use Your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and
                policies.The Company will also retain Usage Data for internal
                analysis purposes. Usage Data is generally retained for a
                shorter period of time, except when this data is used to
                strengthen the security or to improve the functionality of Our
                Service, or We are legally obligated to retain this data for
                longer time periods.
              </p>
              <div className="h4 fw-bold">Transfer of Your Personal Data</div>
              <p className="indent text-muted h6 fw-normal">
                Your information, including Personal Data, is processed at the
                Company’s operating offices and in any other places where the
                parties involved in the processing are located. It means that
                this information may be transferred to — and maintained on —
                computers located outside of Your state, province, country or
                other governmental jurisdiction where the data protection laws
                may differ than those from Your jurisdiction. Your consent to
                this Privacy Policy followed by Your submission of such
                information represents Your agreement to that transfer. The
                Company will take all steps reasonably necessary to ensure that
                Your data is treated securely and in accordance with this
                Privacy Policy and no transfer of Your Personal Data will take
                place to an organization or a country unless there are adequate
                controls in place including the security of Your data and other
                personal information.
              </p>
              <div className="h4 fw-bold">Delete Your Personal Data</div>
              <p className="indent text-muted h6 fw-normal">
                You have the right to delete or request that We assist in
                deleting the Personal Data that We have collected about You. Our
                Service may give You the ability to delete certain information
                about You from within the Service. You may update, amend, or
                delete Your information at any time by signing in to Your
                Account, if you have one, and visiting the account settings
                section that allows you to manage Your personal information. You
                may also contact Us to request access to, correct, or delete any
                personal information that You have provided to Us. Please note,
                however, that We may need to retain certain information when we
                have a legal obligation or lawful basis to do so.
              </p>
              <div className="h3 fw-bold text-decoration-underline mb-2">
                Disclosure of Your Personal Data
              </div>
              <div className="h4 fw-bold">Business Transactions</div>
              <p className="indent text-muted h6 fw-normal">
                If the Company is involved in a merger, acquisition or asset
                sale, Your Personal Data may be transferred. We will provide
                notice before Your Personal Data is transferred and becomes
                subject to a different Privacy Policy.
              </p>
              <div className="h4 fw-bold">Law enforcement</div>
              <p className="indent text-muted h6 fw-normal">
                Under certain circumstances, the Company may be required to
                disclose Your Personal Data if required to do so by law or in
                response to valid requests by public authorities (e.g. a court
                or a government agency).
              </p>
              <span className="fw-semi_bold text-dark">
                Other legal requirements
              </span>
              <p className="indent text-muted h6 fw-normal">
                The Company may disclose Your Personal Data in the good faith
                belief that such action is necessary to:
                <ul className="indent text-muted">
                  <li className="indent text-muted">
                    Comply with a legal obligation
                  </li>
                  <li>
                    Protect and defend the rights or property of the Company
                  </li>
                  <li>
                    Prevent or investigate possible wrongdoing in connection
                    with the Service
                  </li>
                  <li>
                    Protect the personal safety of Users of the Service or the
                    public
                  </li>
                  <li>Protect against legal liability</li>
                </ul>
              </p>
              <div className="h4 fw-bold">Security of Your Personal Data</div>
              <p className="indent text-muted h6 fw-normal">
                The security of Your Personal Data is important to Us, but
                remember that no method of transmission over the Internet, or
                method of electronic storage is 100% secure. While We strive to
                use commercially acceptable means to protect Your Personal Data,
                We cannot guarantee its absolute security.
              </p>
              <div className="h4 fw-bold">Children’s Privacy</div>
              <p className="indent text-muted h6 fw-normal">
                Our Service does not address anyone under the age of 13. We do
                not knowingly collect personally identifiable information from
                anyone under the age of 13. If You are a parent or guardian and
                You are aware that Your child has provided Us with Personal
                Data, please contact Us. If We become aware that We have
                collected Personal Data from anyone under the age of 13 without
                verification of parental consent, We take steps to remove that
                information from Our servers. If We need to rely on consent as a
                legal basis for processing Your information and Your country
                requires consent from a parent, We may require Your parent’s
                consent before We collect and use that information.
              </p>
              <div className="h4 fw-bold">Links to Other Websites"</div>
              <p className="indent text-muted h6 fw-normal">
                Our Service may contain links to other websites that are not
                operated by Us. If You click on a third party link, You will be
                directed to that third party’s site. We strongly advise You to
                review the Privacy Policy of every site You visit. We have no
                control over and assume no responsibility for the content,
                privacy policies or practices of any third party sites or
                services.
              </p>
              <div className="h4 fw-bold">Changes to this Privacy Policy</div>
              <p className="indent text-muted h6 fw-normal">
                We may update Our Privacy Policy from time to time. We will
                notify You of any changes by posting the new Privacy Policy on
                this page. We will let You know via email and/or a prominent
                notice on Our Service, prior to the change becoming effective
                and update the “Last updated” date at the top of this Privacy
                Policy. You are advised to review this Privacy Policy
                periodically for any changes. Changes to this Privacy Policy are
                effective when they are posted on this page.
              </p>
              <div className="h4 fw-bold">Contact Us</div>
              <p className="indent text-muted h6 fw-normal">
                If you have any questions about this Privacy Policy, You can
                contact us:
                <ul>
                  <li>By email: info@codeelan.com</li>
                  <li>
                    By visiting this page on our website:
                    https://codeelan.com/contact-us/
                  </li>
                  <li>By phone number: (820) 865-0598</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </PageContainer>
  );
}
