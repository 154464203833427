import React from "react";
import PageContainer from "../../components/container/Container";
import ServicesHeader from "../../components/services/ServicesHeader";
import bg1 from "../../assets/images/Banner_imgs/solution_2.jpg";
import testImage from "../../assets/images/graphic.jpg";

function TestAutoFramework() {
  return (
    <PageContainer title={"Test Automation Framework"}>
      <ServicesHeader title={"Test Automation Framework"} bgImage={bg1} />
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* <div className=" p-5 bg-light"> */}
              {/*<p>
                  <span className="fw-bold ">
                    Something big is coming, and it’s set to transform how you
                    approach QA automation.
                  </span>
                  <br />
                  <br />
                  <p className="text-muted mb-0">
                    Our soon-to-launch tool will streamline your testing
                    processes, providing an all-in-one solution that simplifies
                    test creation, execution, and reporting—all without the
                    complexities you're used to. It’s built for flexibility,
                    adaptability, and precision, offering the power to automate
                    across various platforms effortlessly. Whether working on
                    complex systems or scaling up your operations, this tool
                    ensures efficiency and accuracy at every step. Get ready to
                    experience a more intelligent, faster, and intuitive way to
                    automate. Don’t be the last to upgrade—this innovation is
                    coming!
                  </p>
                </p>*/}
              {/* </div> */}
              <h4 className="fw-bold">
                Simplify and Streamline Testing Across Platforms
              </h4>
              <h6>
                Unified frameworks tailored for web, mobile, and API automation
                with integrated performance testing tools.{" "}
              </h6>
              <div className="row mt-4 text-justify">
                <div className="col-lg-7">
                  <h6 className="fw-bold">Key Value Propositions</h6>
                  <ol className="indent">
                    <li>Faster time-to-market with scalable automation. </li>
                    <li>
                      Reduced costs via reusable scripts and open-source tools.
                    </li>
                    <li>
                      Seamless integration with existing CI/CD pipelines for
                      agile delivery.
                    </li>
                  </ol>
                  <div>
                    <p className="fw-bold">1. Appium Framework</p>
                    <p className="text-muted fw-bold">
                      Accelerate Mobile Test Automation{" "}
                    </p>
                    <ul className="ms-4">
                      <li>
                        Effortless Automation:{" "}
                        <span className="text-muted">
                          Converts manual test cases into scripts.
                        </span>{" "}
                      </li>

                      <li>
                        Cross-Platform Support:{" "}
                        <span className="text-muted">
                          {" "}
                          Run a single script on both Android and iOS.
                        </span>
                      </li>

                      <li>
                        Reusable and Flexible:{" "}
                        <span className="text-muted">
                          {" "}
                          Reuse scripts across devices, real or emulated.
                        </span>
                      </li>

                      <li>
                        Enhanced Efficiency:{" "}
                        <span className="text-muted">
                          {" "}
                          Execute tests in parallel, distributed, or sequential
                          modes, saving time.
                        </span>
                      </li>

                      <li>
                        Scalable Automation:{" "}
                        <span className="text-muted">
                          {" "}
                          Build once, execute across environments, ensuring
                          efficiency and consistency in testing.
                        </span>{" "}
                      </li>
                    </ul>
                    <p>
                      {" "}
                      <span className="fw-bold">Customer Impact:</span> Reduced
                      script maintenance and faster test execution across mobile
                      platforms.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <img src={testImage} alt="testImage" className="img-fluid" />
                </div>
              </div>
              <div className="text-justify">
                <p className="fw-bold">2. Selenium Grid with Docker</p>
                <p className="fw-bold text-muted">
                  Scalable and Reliable Browser Testing
                </p>
                <ul className="ms-4">
                  <li>
                    Consistent Environments:
                    <span className="text-muted">
                      Docker containers eliminate dependency conflicts.{" "}
                    </span>
                  </li>

                  <li>
                    Multi-Browser, Multi-Platform:
                    <span className="text-muted">
                      {" "}
                      Supports diverse browsers and OS versions.
                    </span>
                  </li>

                  <li>
                    CI/CD Integration:
                    <span className="text-muted">
                      {" "}
                      Automates tests within DevOps workflows.
                    </span>
                  </li>

                  <li>
                    Fault-Tolerant:
                    <span className="text-muted">
                      {" "}
                      Auto-replace crashed nodes and balance test loads.
                    </span>
                  </li>
                </ul>
                <p>
                  {" "}
                  <span className="fw-bold">Customer Impact:</span> Scalable
                  testing with lower resource costs and high reliability.
                </p>
              </div>
              <div>
                <p className="fw-bold">3. Web and API Automation</p>
                <p className="fw-bold text-muted">
                  Robust Web and API Testing Made Simple
                </p>
                <ul className="ms-4">
                  <li>
                    Auto-Healing Scripts:
                    <span className="text-muted">
                      Reduces maintenance by adjusting to UI changes.
                    </span>
                  </li>

                  <li>
                    Record-and-Play:
                    <span className="text-muted">
                      {" "}
                      Enables quick automation setup for non-technical users.
                    </span>
                  </li>

                  <li>
                    Reliable API Validation:
                    <span className="text-muted">
                      {" "}
                      JSON schema validation ensures API consistency.
                    </span>
                  </li>

                  <li>
                    Enhanced Resilience:
                    <span className="text-muted">
                      {" "}
                      Auto-rerun feature retries failed cases.
                    </span>
                  </li>
                </ul>
                <p>
                  {" "}
                  <span className="fw-bold">Customer Impact:</span> Reduced
                  manual effort and consistent API performance validation.
                </p>
              </div>
              <div>
                <p className="fw-bold">
                  4. JMeter with IntelliJ, InfluxDB, and Grafana{" "}
                </p>
                <p className="fw-bold text-muted">
                  Comprehensive Performance Testing Ecosystem
                </p>
                <ul className="ms-4">
                  <li>
                    Advanced Development:
                    <span className="text-muted">
                      IntelliJ streamlines scripting and debugging.
                    </span>
                  </li>

                  <li>
                    Real-Time Metrics:
                    <span className="text-muted">
                      {" "}
                      InfluxDB stores performance data efficiently.
                    </span>
                  </li>

                  <li>
                    Dynamic Dashboards:
                    <span className="text-muted">
                      {" "}
                      Grafana visualizes trends for actionable insights.
                    </span>
                  </li>

                  <li>
                    Scalable and Cost-Effective:
                    <span className="text-muted">
                      {" "}
                      Supports distributed testing with open-source tools.
                    </span>
                  </li>
                </ul>
                <p>
                  {" "}
                  <span className="fw-bold">Customer Impact:</span> End-to-end
                  performance testing for scalable and efficient monitoring.
                </p>
              </div>
              <div className="mt-4">
                <h3>Why Choose Us?</h3>
                <ul className="indent">
                  <li>Unified frameworks tailored for every testing need.</li>
                  <li>
                    Scalable, cost-effective, and easily integrated solutions.
                  </li>
                  <li>
                    Proven track record of enabling faster, reliable, and agile
                    product releases.
                  </li>{" "}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageContainer>
  );
}

export default TestAutoFramework;
