import React from "react";
import { Link } from "react-router-dom";

import PageContainer from "../../components/container/Container";
import ServicesHeader from "../../components/services/ServicesHeader";
import bg1 from "../../assets/images/Banner_imgs/press-release.jpg";
import { AiOutlineCalendar, AiOutlineUser } from "../../assets/icons/vander";
import { Container } from "react-bootstrap";

function PRelease() {
  return (
    <PageContainer
      // title={"AI/ML"}
      description={
        "This page is about AI/ML solution provided by CodeElan Technologies"
      }
    >
      <ServicesHeader title={"Press Release"} bgImage={bg1} />
      <Container title={"Press Release"}>
        <section className="bg-half-80 d-table w-100 pb-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="title-heading">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <AiOutlineUser className="mb-0 me-1 text-dark h5" />
                      <Link to="#" className="text-primary h6">
                        CodeElan Technologies
                      </Link>
                    </li>
                    <li className="list-inline-item text-muted h6 ms-3">
                      <AiOutlineCalendar className="mb-0 me-1 text-dark h5" />{" "}
                      5th Nov 2024
                    </li>
                    <li>
                      <Link
                        to="/home"
                        className="btn btn-lg btn-pills btn-primary"
                      >
                        Home
                      </Link>
                    </li>
                  </ul>
                  <h3 className="mt-3 mb-5">
                    CodeElan Technologies Unveils New Website, Platform, and
                    Product Development
                  </h3>
                  <p className="text-muted fw-normal mb-0">
                    <span className="fw-bold text-dark">
                      CodeElan Technologies
                    </span>{" "}
                    , a pioneer in IT industry, is proud to announce the launch
                    of its newly redesigned website, www.codeelan.com, alongside
                    the introduction of a cutting-edge platform and exciting new
                    product development. This comprehensive update underscores
                    our commitment to innovation and excellence in serving our
                    valued customers.{" "}
                  </p>
                  <h4 className=" fw-bolder" style={{ marginTop: 5 }}>
                    Key Highlights of the Launch Include:
                  </h4>
                  <h6 className="text-dark fw-bold">1. Website Design:</h6>{" "}
                  <p className="text-muted">
                    Our revamped website features a sleek, modern design with
                    enhanced navigation, making it easier than ever for users to
                    find the information they need.{" "}
                  </p>
                  <h6 className="text-dark fw-bold">2. Innovative Platform:</h6>{" "}
                  <p className="text-muted">
                    The new AI-powered Computer Vision platform offers advanced
                    video analytics capabilities, leveraging both recorded and
                    live-streaming footage. This platform generates valuable
                    data that can be utilized for training other AI models,
                    providing users with a versatile and robust tool suitable
                    for a wide range of industries. Its comprehensive features
                    empower organizations to unlock deeper insights, optimize
                    operations, and drive innovation across various sectors.
                  </p>
                  <h6 className="text-dark fw-bold">3. Product Development:</h6>{" "}
                  <p className="text-muted">
                    We are excited to announce the launch of two groundbreaking
                    products: OmniScientia, an AI-powered computer vision-based
                    video analytics platform to address a wide range of use
                    cases under on umbrella, and our comprehensive QA Automation
                    Platform. offering robust capabilities for web, mobile app,
                    API testing, and performance testing. These innovative
                    additions underscore our commitment to continuously evolving
                    and meeting the dynamic needs of our customers, empowering
                    them to achieve greater efficiency and success in their
                    digital transformation journey.
                  </p>
                  <h6 className="text-dark fw-bold">4. Continued Services:</h6>
                  <p className="text-muted">
                    While we innovate, we remain committed to providing the
                    high-quality services our customers have come to rely on,
                    including [list key services].
                  </p>
                  <p className="text-muted">
                    “We are excited to launch our new website and platform,
                    which represent significant milestones in our journey to
                    deliver exceptional value to our customers,” said
                    Chandrakanth Dollin (CD), CEO of CodeElan Technologies.
                  </p>{" "}
                  <p className="text-muted">
                    “The new developments in the organization are a testament to
                    our relentless pursuit of innovation and our dedication to
                    enhancing the customer experience.” said Deepak Mathur (DM),
                    COO of CodeElan Technologies.
                  </p>
                  <h4 className="  fw-bolder">About CodeElan Technologies:</h4>
                  <p className="text-muted">
                    CodeElan Technologies is an innovative software development
                    firm specializing in custom solutions for businesses. With
                    expertise in AI based computer vision solution, web and
                    mobile app development, software/process automation, data
                    analytics, and digital transformation. CodeElan focuses on
                    enhancing operational efficiency and driving growth through
                    cutting-edge technology. Their dedicated team ensures
                    quality and client satisfaction in every project. For more
                    information, visit our Contact Us page and share the
                    details. We will reach out to you at the earliest.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </PageContainer>
  );
}

export default PRelease;
